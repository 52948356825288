import useProductResultPage from "../../../hooks/useProductResultPage";
import useVehicleSidebar from "../../../hooks/useVehicleSidebar";
import {usePrpStore} from '/contexts/prpContext'
import ClassNames from 'classnames'
import SelectedFacets from '../../options/selectedFacets/selectedFacets';
import TextLink from "Clutch/Atoms/TextLink";
import LayerColorFinder from "Clutch/Utilities/LayerColorFinder";
import Typography from "Clutch/Atoms/Typography";
import Svg from "Clutch/Atoms/Svg";
import styles from './sidebarDrawerFacets.module.scss'
import styled from "styled-components";
import {useVehicleStore} from "Clutch/Stores/VehicleStore";

const SidebarDrawerFacets = () => {

    const selectedFacets = useProductResultPage(x => x.selectedFacetOptions)

    const {isVehicleSelected, vehicleName} = useVehicleSidebar(selectedFacets);
    const openAddOrSelectVehicleExperience = useVehicleStore(state => state.openAddOrSelectVehicleExperience)
    const CloseSidebarDrawer = usePrpStore(x => x.CloseSidebarDrawer)

    const brand = 'secondary'
    const backgroundColor = LayerColorFinder(3, false, brand, null)
    const baseColor = LayerColorFinder(0);
    const gradientColors = {
        "--b": baseColor,
        "--i": baseColor.slice(0, -2) + "0.0)",
    };

    const filterFacetsCount = selectedFacets?.filter(currentFacet => currentFacet.searchResponseFacetOptions).length
    const facetsSelected = filterFacetsCount > 0;
    const selectedFacetText = facetsSelected ? `(${filterFacetsCount})` : "";

    return (
        <div className={facetsSelected ? styles.onlyChips : ClassNames(styles.scroll, styles.facetSelected)}>
            <StyledChip
                backgroundColor={backgroundColor}
                borderColor={backgroundColor}
                onClick={() => openAddOrSelectVehicleExperience(true, CloseSidebarDrawer)}
                className={styles.chip}
                as={"div"}
            >
                        <span
                            className={styles.chip_contents}
                            data-testid={`filterByVehicleButton-mobile`}
                        >
                            <Typography
                                size={0.875}
                                className={styles.chip_value}
                            >
                                {isVehicleSelected ? vehicleName : 'Select Vehicle'}
                            </Typography>
                            <Svg icon={isVehicleSelected ? "close" : "chevron"} tone={"contrast"} size={0.5}
                                 className={styles.vehicleSelectIcon}></Svg>
                        </span>
            </StyledChip>
            <SelectedFacets isDesktop={false} location={"mobile"} wrap/>
        </div>
    )
}

const StyledChipDivider = styled.div`
    border-right-color: ${(props) => props.dividerColor};
`;
const StyledChip = styled(TextLink)`
    background-color: ${(props) => `${props.backgroundColor}`};
    border-color: ${(props) => `${props.borderColor}`};
`;

export default SidebarDrawerFacets